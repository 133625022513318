import { render, staticRenderFns } from "./collecIndex.vue?vue&type=template&id=3979a7ba&scoped=true"
import script from "./collecIndex.vue?vue&type=script&lang=js"
export * from "./collecIndex.vue?vue&type=script&lang=js"
import style0 from "./collecIndex.vue?vue&type=style&index=0&id=3979a7ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3979a7ba",
  null
  
)

export default component.exports